.expand-icon {
  transition: transform 0.3s ease;

  .minus {
    position: absolute;
    transition: transform 0.3s ease;

    &.rotated {
      transform: rotate(90deg);
    }
  }

  &.expanded {
    .minus {
      &.rotated {
        transform: rotate(0deg);
      }
    }
  }
}
