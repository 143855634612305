/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
.r-c-i {
  display: flex !important;
}

.blur-image {
  background-image: url('./../../assets/images/contractBlur-min.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}
.blur-container {
  min-height: calc(100vh - 56px);
}

@media (min-width: 768px) {
  .blur-container {
    min-height: calc(100vh - 80px);
  }
}

.switch-modal .modal-content {
  border: 0;
  height: 100%;
}
.switch-modal .modal-body {
  align-items: center;
  display: flex;
  flex-direction: column;
}
