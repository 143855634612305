.addAllowanceBtn {
  height: 56px;
  background-color: var(--primary-20) !important;

  &:hover,
  &:active,
  &:focus {
    background-color: var(--primary-30) !important;
    color: var(--primary-100) !important;
  }
}
